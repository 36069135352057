import { Observer } from 'mobx-react';
import React from 'react';

import { BidRegistryCommercialStore } from '@stores/bidRegistryCommercialStore';
import companyStore from '@stores/companyStore/companyStore';
import { registerOfBetsStore } from '@stores/registerOfBetsStore';
import { BidRegistry } from '@stores/registerOfBetsStore/registerOfBetsStore.namespace';

export const COLUMNS = [
  {
    title: 'Маршрут',
    dataIndex: 'bidRegistryId',
    key: 'bidRegistryId',
    render: (_, { loadStationName, destStationName }) => {
      return `${loadStationName}-${destStationName}`;
    },
  },
  {
    title: 'Ставка без НДС',
    dataIndex: 'priceWithoutNds',
    key: 'priceWithoutNds',
    width: 130,
  },
  {
    title: 'НДС',
    key: 'ndsType',
    render: () => BidRegistry.getNdsLabel(registerOfBetsStore.bidRegistry.ndsRate),
    width: 120,
  },
  {
    title: 'Ставка с НДС',
    dataIndex: 'ndsType',
    key: 'record.nds',
    render: (_, { freightEtsngCode, loadStationCode, destStationCode }) => {
      return (
        <Observer>
          {() => {
            const priceWithoutNdsField = `rent-priceWithoutNds-${freightEtsngCode}-${loadStationCode}-${destStationCode}`;
            const priceWithoutNds = BidRegistryCommercialStore.flatRollingStocks[priceWithoutNdsField];
            const ndsRate = registerOfBetsStore.bidRegistry.ndsRate;

            return <>{BidRegistry.calcNds(ndsRate, priceWithoutNds)}</>;
          }}
        </Observer>
      );
    },
    width: 130,
  },
];
